import React from "react";
import "./App.css";

const App: React.FC = () => {
  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const amazonLink =
    "https://www.amazon.com.au/Multiple-Sclerosis-One-Owners-Manual/dp/0645468002";
  const booktopiaLink =
    "https://www.booktopia.com.au/multiple-sclerosis-andrew-scott/book/9780645468007.html";
  const thriftBooksLink =
    "https://www.thriftbooks.com/w/multiple-sclerosis-one-owners-manual/37691560/#edition=65680132&idiq=54689269";

  return (
    <div className="App">
      <header className="App-header">
        <h1>Multiple Sclerosis One Owner's Manual</h1>
        <a href={amazonLink} target="_blank" rel="noopener noreferrer">
          <button>Buy on Amazon</button>
        </a>
        <a href={booktopiaLink} target="_blank" rel="noopener noreferrer">
          <button>Buy on Booktopia</button>
        </a>
        <a href={thriftBooksLink} target="_blank" rel="noopener noreferrer">
          <button onClick={() => scrollToSection("store3")}>
            Buy on Thriftbooks
          </button>
        </a>
      </header>

      <main>
        <a href={amazonLink} target="_blank" rel="noopener noreferrer">
          <img
            src="https://m.media-amazon.com/images/I/61RWkSpCGWL._SL1360_.jpg"
            alt="Multiple Sclerosis One Owner's Manual"
            width={"50%"}
          />
        </a>
        <a href={amazonLink} target="_blank" rel="noopener noreferrer">
          <img
            src="https://m.media-amazon.com/images/I/71zpVsWfZ-L._SL1360_.jpg"
            alt="Multiple Sclerosis One Owner's Manual"
            width={"50%"}
          />
        </a>
        <section id="description" className="App-section">
          <h2>About the Book</h2>
          <p>
            Andrew Scott was diagnosed with MS in 1994 but had all the early
            signs well before that. Upon diagnosis, apart from a three-day
            course of steroids, he was offered nothing that would treat his
            condition. The advice was to go home and wait for the next attack.
          </p>
          <p>
            Twenty years later he walked into an emergency ward and said, "I
            think I need some help". This time, he was offered disease modifying
            therapies but no explanation of how they worked.
          </p>
          <p>
            In the 17 years between consultations with neurologists, the basic
            message was unchanged: We don't know what causes this.
          </p>
          <p>
            This book is a summary of what Scott has learned over nearly three
            decades of dealing with MS. It is designed for medical advisors, for
            people who want to learn about MS, and most importantly, for those
            who have the condition.
          </p>
          <p>
            In this book, Scott addresses issues associated with MS such as
            fatigue, spasticity, bowel and bladder issues, pain, posture, and
            speech problems. The book offers suggestions on dealing with MS and
            provides an in-depth detailed explanation of how important the
            energy molecule ATP is to maintaining health. Scott explains how
            muscles contract and what needs to be done to help them relax.
          </p>
          <p>
            Andrew Scott continues to lead a normal life without resorting to
            immunosuppressive drugs. In this book, he shares his story in the
            hope that it will help you with your own MS journey.
          </p>
        </section>
      </main>
    </div>
  );
};

export default App;
